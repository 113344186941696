/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import 'popper.js'
import './download/bootstrap-4.4.1-dist/js/bootstrap.min';
// import './download/elegant_font/HTML CSS/lte-ie7';
import './download/jquery-nice-select-1.1.0/js/jquery.nice-select.min';
import './download/jquery-ui-1.12.1/jquery-ui.min';
import './download/Magnific-Popup-master/dist/jquery.magnific-popup.min';
import './download/OwlCarousel2-2.3.4/dist/owl.carousel.min';
import './download/SlickNav-master/dist/jquery.slicknav.min';
import './main'
// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

